import React, { useContext, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom'; 
import * as Yup from 'yup';
import Header from './header';
import { UserContext } from '../categories/contextapi';

const LoginPage = () => {
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate(); 
  const { setUserEmail,setUserFirstname } = useContext(UserContext);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setApiError('');
    try {
      // API request to login
      const response = await fetch('https://37h.4ad.mytemp.website/gsg/api/user/login/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: values.email, password: values.password  }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        setApiError(errorData?.message || 'Login failed. Please try again.');
        setSubmitting(false);
        return;
      }

      const data = await response.json();
      console.log('Login Successful:', data);
  
      // Store access & refresh tokens
      localStorage.setItem('accessToken', data.access);
      localStorage.setItem('refreshToken', data.refresh);
  
      // Call API to fetch user details using accessToken
      await fetchUserProfile(data.access);
      
    } catch (error) {
      console.error('Login error:', error);
      setApiError('Failed to connect to the server.');
    } finally {
      setSubmitting(false);
    }
  };
  const fetchUserProfile = async (token) => {
    try {
      const userResponse = await fetch('https://37h.4ad.mytemp.website/gsg/api/user/get/', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!userResponse.ok) {
        console.error('Failed to fetch user details');
        return;
      }
  
      const userData = await userResponse.json();
      console.log('User Profile Data:', userData);
  
      // Store first name & email in localStorage
      localStorage.setItem('userFirstName', userData.first_name);
      localStorage.setItem('userEmail', userData.email);
      localStorage.setItem("userId", userData.id);
      navigate('/');
      
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleGoToSignup = () => {
    navigate('/signup');
  }
  const handleGoToForgotPassword = () => {
    navigate('/forgotpassword');
  };

  return (
    <>
      <Header />
      <div className="page-container">
        <div className="login-form-container">
          <h2 className="form-header">Sign In</h2>

          {apiError && <div className="form-error api-error">{apiError}</div>}

          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-input"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage name="email" component="div" className="form-error" />
                </div>

                <div className="form-group">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="form-input"
                    placeholder="Enter your password"
                  />
                  <ErrorMessage name="password" component="div" className="form-error" />
                </div>
                <button type="submit" className="form-submit-btn" disabled={isSubmitting}>
                  {isSubmitting ? 'Signing in...' : 'Sign In'}
                </button>
              </Form>
            )}
          </Formik>
          {/* forgot password */}
          <div className="form-footer form-footer-resp text-center d-flex align-items-end">
              <span className='login-acc-conf'>Don't have an account? <a href="javascript:void(0);" onClick={handleGoToSignup}>Sign up</a></span>
              <a href="javascript:void(0);" onClick={handleGoToForgotPassword} className="forgot-password-link">Forgot Password?</a>
        </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
