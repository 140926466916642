import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"; // changed to error icon
import mainlogo from "../images/logo.png";

const ResetInvalid = () => {
  return (
    <div className="reset-container d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="reset-box text-center p-4 rounded" style={{ maxWidth: "400px", backgroundColor: "#fff" }}>
        <div className="mb-4">
          <img src={mainlogo} alt="Company Logo" className="img-fluid" style={{ maxWidth: "180px" }} />
        </div>

        <FontAwesomeIcon icon={faCircleExclamation} className="mb-3" style={{ fontSize: "2.5rem",color:"#c42c06"}} />
        <h2 className="reset-link-title">Invalid or Expired Link</h2>
        <p className="text-muted mb-4">
          The reset link you used is either invalid or has expired. Please request a new password reset email.
        </p>

        <div className="d-flex gap-2">
          <Link to="/login" className="reset-back-to-login text-light">
            Back to Login
          </Link>
          <Link to="/" className="reset-back-to-login text-light">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetInvalid;
