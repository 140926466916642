import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import mainlogo from "../images/logo.png";

const ForgotPassword = () => {
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email address").required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
      setApiError("");
      setApiSuccess("");

      try {
        // API request
        const response = await axios.post(
          "https://37h.4ad.mytemp.website/gsg/api/user/forgot_password/",
          {
            email: values.email
          }
        );

        if (response.status === 200) {
          setApiSuccess(response.data.message || "Password reset link sent to your email!");
          setTimeout(() => navigate("/resetlink"), 2000);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
              setApiError(error.response.data.error);
            } else if (error.response.status === 404) {
              setApiError(error.response.data.error || "Email not found.");
            } else {
            setApiError("Something went wrong. Please try again.");
          }
        }
      }

      setSubmitting(false);    
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="col-md-6 col-lg-4 text-center">
        {/* Company Logo */}
        <div className="mb-3 d-flex justify-content-center">
          <img src={mainlogo} alt="Company Logo" className="img-fluid" style={{ maxWidth: "200px" }} />
        </div>
        <h3 className="fw-bold mb-3">Did you forget your password?</h3>
        <p className="text-muted mb-4">
          Enter your email address below and we'll send you a password reset link.
        </p>
        {apiError && <div className="form-error api-error">{apiError}</div>}
        {apiSuccess && <div className="form-success">{apiSuccess}</div>}
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-100">
              <div className="mb-3 text-start">
                <label htmlFor="email" className="form-label fw-bold">Email Address</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="you@example.com"
                />
                <ErrorMessage name="email" component="div" className="text-danger mt-1" />
              </div>

              <button
                type="submit"
                className="btn btn-dark w-100"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Processing..." : "Request Reset Link"}
              </button>
            </Form>
          )}
        </Formik>

        <div className="mt-3">
          <Link to="/login" className="text-decoration-underline">Back to Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
