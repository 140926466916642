import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/animate.css';
import './css/slicknav.min.css';
import './css/reset.css';
import './style.css';
// import './responsive.css';
// import './css/responsive.css';
import 'font-awesome/css/font-awesome.min.css';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './fonts/themifyicons/themify-icons.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from './components/home';
import React from "react";
import CategoryPage from './categories/categorypage';
import SearchResultsPage from './categories/searchresults_page';
import Viewproduct from './components/viewproduct';
import Login from './components/login';
import Signup from './components/signup';
import Contact from './components/contact';
import UserProvider from './categories/contextapi';
import CartPage from './categories/cartPage';
import CheckoutPage from './categories/checkoutPage';
import { CartProvider } from './categories/cartcontext';
import PaymentPage from './components/paymentPage';
import OrderSuccess from './components/ordersuccess';
import OrderHistory from './components/orderhistory';
import ChangePassword from './components/changepassword';
import Trackorder from './components/trackorder';
import ForgotPassword from './components/forgotpassword';
import ResetLinkSent from './components/resetlink';
import ResetPassword from './components/resetpassword';
import ResetInvalid from './components/resetinvalid';
import ResetSuccess from './components/resetsuccess';

function App() {
  return (
    <UserProvider>
    <Router>
      <CartProvider>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/category" element={<CategoryPage />}></Route>
        <Route path="/category/:id" element={<CategoryPage />}></Route>
        <Route path="/search" element={<SearchResultsPage />} />
        <Route path="/viewproduct/:id" element={<Viewproduct />} />
        <Route path="/cartPage" element={<CartPage/>}></Route>
        <Route path="/checkoutPage" element={<CheckoutPage/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/signup" element={<Signup/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/paymentPage" element={<PaymentPage/>}></Route>
        <Route path="/ordersuccess" element={<OrderSuccess/>}></Route>
        <Route path="/orderhistory" element={<OrderHistory/>}></Route>
        <Route path="/changepassword" element={<ChangePassword/>}></Route>
        <Route path="/trackorder/:orderId" element={<Trackorder/>}></Route>
        <Route path='/forgotpassword' element={<ForgotPassword/>}></Route>
        <Route path='/resetlink' element={<ResetLinkSent/>}></Route>
        <Route path='/resetpassword' element={<ResetInvalid/>}></Route>
        <Route path='/resetpassword/:uid/:token' element={<ResetPassword />} />
        <Route path='/resetsuccess' element={<ResetSuccess/>}></Route>
        
      </Routes>
      </CartProvider>
    </Router>
    </UserProvider>
  );
}

export default App;
