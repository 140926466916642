import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import mainlogo from "../images/logo.png";
import { useParams, useNavigate} from 'react-router-dom';

const ResetPassword = () => {
  const { uid, token } = useParams();
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your new password"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setApiError("");
    setApiSuccess("");

    try {
      // API request
      const response = await axios.post(
        "https://37h.4ad.mytemp.website/gsg/api/user/reset_password/",
        {
          uid: uid,
          token: token,
          new_password: values.newPassword,
        }
      );

      if (response.status === 200) {
        setApiSuccess(response.data.message || "Password reset successful!");
        setTimeout(() => navigate("/resetsuccess"), 2000);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
            setApiError(error.response.data.error);
          } else if (error.response.status === 404) {
            setApiError(error.response.data.error || "Invalid or expired link.");
          } else {
          setApiError("Something went wrong. Please try again.");
        }
      }
    }

    setSubmitting(false);
  };

  return (
    <div className="reset-pwd-container">
      <div className="reset-pwd-box col-md-6 col-lg-4">
            <div className="mb-3 d-flex justify-content-center">
            <img src={mainlogo} alt="Company Logo" className="img-fluid" style={{ maxWidth: "200px" }} />
            </div>
        <h2>Reset Password</h2>
        {apiError && <div className="form-error api-error">{apiError}</div>}
        {apiSuccess && <div className="form-success">{apiSuccess}</div>}        
        <Formik
          initialValues={{ newPassword: "",confirmPassword: ""}}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <Field type="password" id="newPassword" name="newPassword" className="form-input" />
                <ErrorMessage name="newPassword" component="div" className="form-error" />
              </div>

              <div className="form-group">
                <label htmlFor="newPassword">Confirm Password</label>
                <Field type="password" id="confirmPassword" name="confirmPassword" className="form-input" />
                <ErrorMessage name="confirmPassword" component="div" className="form-error" />
              </div>

              <button type="submit" className="form-submit-btn" disabled={isSubmitting}>
                {isSubmitting ? "Changing..." : "Reset Password"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
