import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"; // changed to error icon
import mainlogo from "../images/logo.png";

const ResetSuccess = () => {
  return (
    <div className="reset-container d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="reset-box text-center p-4 rounded" style={{ maxWidth: "400px", backgroundColor: "#fff" }}>
        <div className="mb-4">
          <img src={mainlogo} alt="Company Logo" className="img-fluid" style={{ maxWidth: "180px" }} />
        </div>

        <FontAwesomeIcon icon={faCircleCheck} className="mb-3" style={{ fontSize: "2.5rem",color:"#c42c06"}} />
        <h2 className="reset-link-title">Password Reset Successful</h2>
        <p className="text-muted mb-4">
        Your password has been reset successfully. You can now sign in with your new password.
        </p>

        <div className="d-flex gap-2">
          <Link to="/login" className="reset-back-to-login text-light">
            Back to Login
          </Link>
          <Link to="/" className="reset-back-to-login text-light">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetSuccess;
